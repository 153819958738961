<template>
  <div class="blog-section">
    <div class="container">
      <h2 class="somePost">آخرین <span>پست های وبلاگ</span></h2>

      <div class="blog-posts">
        <div v-for="post in posts" :key="post.id" class="blog-post">
          <blog-image class="blogImage"
                      :url="post._links['wp:featuredmedia'][0].href">
          </blog-image>
          <div class="blogPostContain">
            <a :href="post.link" style="text-decoration: none !important;"><h2 class="blog-title">{{
                post.title.rendered
              }}</h2></a>
            <div class="post_meta">
              <span>31 خرداد 1399</span>
              <span><i class="fa fa-comment-o"></i> 4</span>
              <span><i class="fa fa-eye"></i> 50</span>
            </div>
            <div class="blog-description">
              <!--                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد.-->
              {{ stripTags(post.excerpt.rendered) }}
            </div>
            <a :href="post.link" class="moreBtn">
              <span>اطلاعات بیشتر</span>
              <i class="fa fa-arrow-left"></i>
            </a>
          </div>
        </div>
      </div>
    </div> <!-- end container -->
  </div> <!-- end blog-section -->
</template>

<script>
import BlogImage from './BlogImage'
import sanitizeHtml from 'sanitize-html'

export default {

  components: {
    BlogImage,
  },
  created() {
    console.log(this.href)
    axios.get(this.href +'/wp-json/wp/v2/posts?per_page=3')
        .then(response => {
          this.posts = response.data
        })
  },
  props:[
      'href'
  ],
  data() {
    return {
      posts: [],

    }
  },
  methods: {
    stripTags(html) {
      return sanitizeHtml(html, {
        allowedTags: []
      }).substring(0, 200)
    }
  }

}
</script>

